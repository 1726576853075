@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
body, hh1, h2, h3, h4, h5, h6, button, span, p {
  font-family: 'Open Sans', sans-serif !important;
}
.ant-layout {
  background: transparent !important;
}
.ant-layout-header {
  background-color: #fff !important;
  height: unset !important;
  padding: 10px 0 !important;
  border-bottom: solid 1px #f2f2f2;
}
.btn_primary {
  background: #0C4266 !important;
  color: #fff !important;
  padding: 12px 30px !important;
  height: unset !important;
  border-radius: 10px !important;
  border: solid 1px #0C4266 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.btn_primary:hover {
  background: #052a42 !important;
  color: #fff !important;
  border-color: #052a42 !important;
}
html, body {
  overflow: auto !important;
}
body {
  padding: 0;
  background-color: #fafafa;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: open sans, Helvetica, Arial, sans-serif;
}
.menu_wrap ul {
  display: flex;
  justify-content: end;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  align-items: center;
}
.menu_wrap ul li {
  padding: 0 20px;
}
.menu_wrap ul li .btn_primary {
  height: auto;
  line-height: 1.5;
}
.login-btn {
  padding-right: 0 !important;
}
.menu_wrap ul li a {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #747281;
}
.menu_wrap ul li a:hover, .menu_wrap ul li a.active {
  color: #0c4266;
}
.banner-bg {
  position: relative;
  overflow: hidden;
}
.banner-bg:after {
  content: '';
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 69.79%);
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  bottom: 0;
}
.banner-cont {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-inner-cont {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
}
.banner-inner-cont h1 {
  font-size: 70px;
  color: #fff;
  line-height: 1.2;
}
.banner-inner-cont p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  max-width: 70%;
  margin: 0 auto;
}
.main-slider .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 90px;
}
.main-slider .owl-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.main-slider .owl-dots .owl-dot.active span {
  width: 40px;
  background: #fff;
}

.main-slider .owl-dots .owl-dot span {
  background: rgb(255 255 255 / 70%);
  box-shadow: 0px 0px 2px 0px #0000004D;
}
.space-cls {
  margin: 90px 0;
}
section.pharmacy-serv-sec.space-cls {
  margin: 0;
  padding: 90px 0;
}
.pharmacy-serv-sec {
  background-color: #F5F3FF;
}
.main-title {
  text-align: center;
  margin-bottom: 20px;
}
.main-title h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}
.themeColor {
  color: #F68B29;
}
.main-title p {
  font-size: 18px;
  font-weight: 400;
  max-width: 50%;
  margin: 0 auto;
}
.serv-box {
  box-shadow: 0px 5px 15px 0px #1018281A;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 25px;
}
.serv-cont h4 {
  font-weight: 600;
  text-align: center;
  font-size: 26px;
  margin-bottom: 20px;
}
.serv-ic {
  height: 45px;
  width: 45px;
  margin: 0 auto;
  background: #094369;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 20px;
}
.serv-cont p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
}
.serv-cont {
  text-align: center;
}
.serv-cont a {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #094369;
}
.serv-ic img {
  max-width: 47px;
}
.text-left {
  text-align: left !important;
}
.prescription-cont p {
  max-width: 100%;
}
.prescription-cont ul {
  margin-bottom: 25px;
  list-style: none;
  padding-left: 0;
}
.prescription-cont ul li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.prescription-cont ul li:after {
  content: '';
  position: absolute;
  left: 0;
  height: 8px;
  width: 8px;
  background: #0C4266;
  border-radius: 50%;
  top: 9px;
}

.prescription-img {text-align: right;} 
.number_box {
  background: linear-gradient(180deg, #4687B1 0%, #094369 100%);
  border-radius: 10px;
  padding: 40px 80px;
}

.number_box ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  justify-content: space-between;
}

.number_box ul li {
  list-style: none;
  padding: 0;
}

.number_box ul li h3 {
  margin-bottom: 0;
  color: #fff;
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}
.number_box ul li span {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  display: block;
  text-align: left;
}
.bookAppointment-sec {
  background-image: url(../../assets/images/appointment-bg.png);
  background-repeat: no-repeat;
  background-size:cover;
  padding: 70px 0;
}
.appointment-cont {
  max-width: 570px;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}
.appointment-cont .main-title {
  margin-bottom: 0;
}
.appointment-cont p {
  max-width: 100%;
  margin-bottom: 20px;
}
.number_box.contact_sec {
  padding: 80px;
}
.number_box.contact_sec h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  margin-bottom: 0;
}
.btn_theme {
  padding: 12px 30px !important;
  height: unset !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  color: #000 !important;
  transition: all 0.5s !important;
}
.btn_theme:hover {
  color: #fff;
  background: #0c4266;
  transition: all 0.5s;
  border-color: #0c4266;
}
.contact_inner {
  text-align: right;
}
.owl-theme .owl-nav [class*=owl-] img {
  max-width: 25px;
}
.owl-theme .owl-nav [class*=owl-] {
  margin: 0 !important;
  background: #fff !important;
  height: 60px;
  width: 60px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  overflow: hidden;
  font-size: 0 !important;
  box-shadow: 0 0 10px #00000024;
}

footer.footer-bg {
  background: linear-gradient(180deg, #4687B1 0%, #094369 100%);
  padding: 40px 0 0;
}

.f-widget-title h2 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}
.footer-link ul{
  padding-left:0;
  list-style:none
}
.footer-link ul li {
  margin-bottom: 10px;
}
.footer-link ul li a {
  color: #fff;
  display: inline-block;
  font-size: 16px;
}
.f-contact {
  margin-top: 15px;
}
.f-contact p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 0;
}
.footer-logo + .f-contact ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  margin-top: 15px;
}
.footer-logo + .f-contact ul li {
  height: 45px;
  width: 45px;
  background: #fff;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-logo + .f-contact ul li a {
  display: block;
  line-height: 1;
}
.working-hours p {
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
}
.f-contact ul {
  display: block;
}
.f-contact ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.f-contact ul li {
  margin-bottom: 10px;
}
.f-contact ul li a {
  color: #fff;
}

.copyright-wrap {
  padding: 12px 0;
  text-align: center;
  border-top: solid 1px #fff;
  margin-top: 25px;
}

.copyright-wrap p {
  margin-bottom: 0;
  color: #fff;
}
.banner-tit-bg {
  position: relative;
  width: 100%;
}
position img {
  width: 100%;
}
.banner-tit-bg img {
  width: 100%;
}
section.about-slider {
  position: relative;
}
.about-title {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 1;
}
.about-title h2 {
  margin-bottom: 0;
  color: #fff;
  font-size: 70px;
  line-height: 1.3;
}
section.about-slider:after {
  content: '';
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 50%);
  top: 0;
  z-index: 0;
}
.about-cont .main-title {
  text-align: left;
}
.about-cont .main-title p {
  text-align: left;
  max-width: 100%;
  font-weight: 600;
}

.about-img {
    text-align: right;
}
.about-independent-sec .about-img {
    text-align:left;
}
.about-independent-sec {
  background: #F5F3FF;
  padding: 90px 0;
}
.about-cont p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    font-family: Open Sans;
}
.team-box {
  background: #F5F3FF;
  padding: 20px;
  border-radius: 10px;
}
.teamImg img {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.teamUser h4 {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}
.teamImg {
  margin-bottom: 12px;
}
.teamUser p {
  margin-bottom: 0;
  color: #000;
}
.team-box {
  height: 100%;
}
.ant-col.ant-form-item-label {
  width: 100%;
  display: block;
  text-align: left;
}

.ant-row.ant-form-item-row {
  flex-wrap: wrap;
}

.ant-col.ant-form-item-control {
  width: 100%;
  flex: 0 0 100% !important;
}
.ant-select {
  height: 45px;
}
.ant-select-selector {
  height: 45px !important;
  align-items: center;
  border-radius: 7px !important;
  border-color: #000 !important;
}
.ant-picker {
  width: 100%;
  height: 45px;
  border-radius: 7px !important;
  border-color: #000 !important;
}
input {
  height: 45px !important;
}
::placeholder {
  color: #303030 !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: #303030 !important;
}

input {
  border-radius: 7px !important;
  border-color: #000 !important;
}

.appointment-sec form.ant-form.ant-form-horizontal {
  max-width: 60%;
  margin: 0 auto;
}
label {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.ant-form-item.uploadWrap button.ant-btn {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 16px;
  color: #202020;
  font-weight: 500;
  border-color: #000;
  border-radius: 7px;
}
.ant-form-item.uploadWrap img {
  max-width: 30px;
}
.ant-form-item.uploadWrap .ant-upload-list-item-info {
  padding: 5px 10px;
  border: solid 1px #4c4c4c;
  height: 40px;
  border-radius: 5px;
}
.ant-form-item.uploadWrap span.ant-upload-list-item-card-actions button.ant-btn {
  min-height: unset;
  border: 0 !important;
}
.ant-form-item.uploadWrap .ant-upload-list-item {
  height:unset
}
.ant-form-item.uploadWrap .ant-upload.ant-upload-select {
  width: 100%;
  text-align: center;
}
.pharmacy_wrap {
  padding-top: 20px;
  border-top: solid 1px #BCBCBC;
}
textarea {
  border-color: #000 !important;
  border-radius: 7px !important;
}
.help-wrap h5 {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}

.help-wrap p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.help-wrap {
  margin-bottom: 30px;
}

.help-wrap a {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}
.help-wrap p {
  margin-bottom: 5px;
  font-size: 16px;
}
.contactForm form.ant-form.ant-form-horizontal {
  max-width: 100%;
}


section.terms-conditions-wrap {
  padding-top: 70px;
}

section.terms-conditions-wrap .terms_inner {
  max-width: 60%;
  margin: 0 auto 35px;
}

section.terms-conditions-wrap .terms_inner h4 {
  font-size: 22px;
  font-weight: bold;
}

.terms_inner p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6;
  margin-bottom: 0;
}

.terms_inner ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 15px;
}

.terms_inner ul li:last-child {
  margin-bottom: 0;
}

.terms_inner ul {
  margin-bottom: 0;
}
.notfound-wrap {
  padding: 70px 0;
}
.notfound-inner {
  text-align: center;
}
.notfound-inner h2 {
  font-size: 120px;
  font-weight: 700;
  line-height: 140px;
  letter-spacing: 0.1em;
  text-align: center;
  color: #094369;
  margin-bottom: 0;
}
.notfound-inner h6 {
  margin: 15px auto;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.notfound-inner p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}
.notfound-inner .ant-btn {
  margin-top: 15px;
}
.appointment-wrap {
  padding: 70px 0;
}

.appointment-left ul {
  background: #F5F3FF;
  max-width: 80%;
  margin-bottom: 0;
  padding: 0;
  border-radius: 10px;
}

.appointment-left ul li {
  list-style: none;
  padding: 10px 15px;
  border-bottom: solid 1px #BCBCBC;
}

.appointment-left ul li a {color: #000000;font-size: 16px;font-weight: 400;letter-spacing: 0em;text-align: left;}

.appointment-left ul li:last-child {
  border-bottom: 0;
}

.appointment-inner-list {
  border-bottom: solid 1px #BCBCBC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.appointment-left h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 0;
}

.appointment-inner-list p {
  margin-bottom: 0;
}

.appointment-inner-list:first-child {
  padding-top: 0;
}

.canceled {
  color: #F1395F;
}

.completed {
  color: #094369;
}

.appointment-right span {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.appointment-left ul li a.active {
  color: #094369;
}
.appointment-inner-list:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.btn_group {
  display: flex;
  align-items: center;
  gap: 15px;
}
.btn_group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn_group .btn_border {
  border: solid 1px #094369;
  border-radius: 6px;
  height: 100% !important;
  min-height: unset;
  padding: 6px 18px !important;
  color: #094369 !important;
}
.btn_group button.btn_update {
  border-radius: 6px;
  background: #094369;
  border:solid 1px #094369;
  color: #fff;
  padding: 6px 18px !important;
  min-height: unset !important;
  height: unset !important;
}
.btn_group .btn_cancel {
  padding: 6px 18px !important;
  height: unset !important;
  border-radius: 6px;
  border: 1px solid #727272;
}
.btn_group button {
  padding: 8px 20px !important;
  height: 45px !important;
  min-height: unset !important;
}
.serv-cont .ant-btn {
  border: 0;
  background: transparent !important;
  padding: 0;
  line-height: 1;
  box-shadow: none !important;
  color: #094369;
  font-weight: 400;
  font-size: 18px;
  height: unset;
}
.serv-cont .ant-btn:hover {
  color: #094369;
}

.serv-box {
  box-shadow: 0px 5px 15px 0px #1018281A;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 12.5px;
  height: calc(100% - 25px);
  margin-top: 12.5px;
  background: #fff;
}
.ServiceDesc {
  display: flex;
}
.ServDesc h2 {
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 20px;
}
.serv-modal-icon {
  height: 55px;
  width: 55px;
  background: #094369;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 55px;
  border-radius: 10px;
  margin-right: 15px;
}
.ServDesc p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
}
.ant-modal.ModalCls {
  margin-bottom: 0 !important;
  padding-bottom: 0  !important;
}
.ant-modal.ModalCls .ant-modal-content {
  border-radius: 15px;
}
.ant-modal.LoginModal h3 {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}
.ant-form-item.formControl input {
  border: solid 1px #999999 !important;
}
.formControl span.ant-input-affix-wrapper.ant-input-password input {
  border: 0 !important;
  padding: 0 10px;
}
.formControl span.ant-input-affix-wrapper.ant-input-password {
  border: solid 1px #999999 !important;
  border-radius: 7px;
  padding: 0;
  box-shadow: none !important;
}
.formControl span.ant-input-affix-wrapper.ant-input-password  span.ant-input-suffix {
  position: absolute;
  right: 0;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  margin: 0;
}
.ant-form-item.formControl.ant-form-item-has-success + .ant-form-item.formControl.ant-form-item-has-success {
  margin-bottom: 0;
}
.ant-modal.LoginModal h3 {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}
.ant-form-item.formControl input {
  border: solid 1px #999999 !important;
}
.formControl span.ant-input-affix-wrapper.ant-input-password input {
  border: 0 !important;
  padding: 0 10px;
}
.formControl span.ant-input-affix-wrapper.ant-input-password {
  border: solid 1px #999999 !important;
  border-radius: 7px;
  padding: 0;
  box-shadow: none !important;
}
.formControl span.ant-input-affix-wrapper.ant-input-password  span.ant-input-suffix {
  position: absolute;
  right: 0;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  margin: 0;
}
.ant-form-item.formControl.ant-form-item-has-success + .ant-form-item.formControl.ant-form-item-has-success {
  margin-bottom: 0;
}
.ant-modal.LoginModal {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.ant-form-item.forgot-pw {
  text-align: right;
}
.ant-form-item.forgot-pw a {
  color: #094369;
  font-size: 16px;
  font-weight: 600;
}
.ant-form-item.forgot-login .btn_primary {
  width: 100%;
}
.ant-form-item.forgot-login .btn_primary {
  width: 100%;
}
.ant-form-item.forgot-login h4 {
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
  color: #303030;
  font-weight: 400;
}
.ant-form-item.forgot-login h4 a {
  color: #094369;
  text-decoration: underline;
}
.ant-form-item.forgot-login {
  margin-bottom: 0;
  margin-top: 10px;
}
.team-box {
  margin-bottom: 20px;
  height: calc(100% - 20px);
}

.react-tel-input .form-control {
  width: 100% !important;
}
/* .menu_wrap button.ant-btn.ant-btn-default.ant-dropdown-trigger.btn_primary span {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.menu_wrap button.ant-btn.ant-btn-default.ant-dropdown-trigger.btn_primary span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu_wrap button.ant-btn.ant-btn-default.ant-dropdown-trigger.btn_primary span.ant-avatar.ant-avatar-circle.ant-avatar-icon span.anticon.anticon-user svg {
    height: 25px;
    width: 25px;
}
.menu_wrap button.ant-btn.ant-btn-default.ant-dropdown-trigger.btn_primary {
  align-items: center;
  display: flex;
  background: transparent !important;
  color: #094369 !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
  padding-left: 20px !important;
}
.footer-logo + .f-contact {
  max-width: 80%;
}
.ant-modal-content {
  border-radius: 15px !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
} 
.formSubmit button.ant-btn {
  width: 200px;
}
img {
  max-width: 100%;
}
section.contact-page.appointment-sec {
  background: #F5F3FF;
  padding: 80px 0;
  margin: 0;
}
section.contact-page.appointment-sec textarea, section.contact-page.appointment-sec input {
  background: transparent;
}
.owl-carousel .owl-item {
  min-height: 0px  !important
}
span.ant-select-selection-placeholder {
  color: #303030;
  font-weight: 600;
  font-size: 16px;
}
.ant-select-arrow .anticon > svg {
  fill: #303030;
}
.ant-picker-input > input:placeholder-shown {
  color: #303030 !important;
  font-size: 16px;
  font-weight: 500;
}
span.ant-picker-suffix span.anticon.anticon-calendar svg {
  fill: #303030;
}
.ant-form-item-label > label {
  height: unset !important;
}
.team-modal-img {
  flex: 0 0 200px;
  margin-right: 15px;
}

.ant-modal.ModalCls.teamModal {
  width: 620px !important;
}

.ant-modal.ModalCls.teamModal .ServiceDesc {
  margin-top: 10px;
}
.dropdown-content {
    position: absolute;
    background: #fff;
    width: 190px;
    box-shadow: 0 0 2px #0000003b;
    right: 0;top: 40px;
    z-index: 11;
}

.dropdown-content a {
    display: block;
    width: 100%;
    border: 0 !important;
    box-shadow: none;
    background: #F5F3FF;
    color: #191919;
}

.dropdown-content  button {
    background: transparent;
    border: 0 !important;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
}
span.dot-wrap {
  margin-left: 10px;
}
.green{
  color: green;
}
.red{
  color: red;
}
.blue{
  color: blue;
}
.selectPara p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #8a8a8a;
  margin-bottom: 8px;
}
.selectPara {
  margin-bottom: 25px;
}
.note-wrap {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 7px;
}
.selectPara .ant-col {
  padding-left: 0 !important;
}