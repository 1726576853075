@media (min-width:1400px) { 
  section.prescription-sec .container, section.number_wrap .container {
    max-width: 1140px !important;
  }
}
@media (max-width:1366.98px) {
  .menu_wrap ul li {
      padding: 0 10px;
  }
  .menu_wrap ul li a {
    font-size: 14px;
  }
  .main-title h2 {
    font-size: 34px;
  }
  .about-cont p {
    font-size: 16px;
  }
  .teamUser h4 {
    font-size: 16px;
  }
}
@media (max-width:1199.98px) {
    .menu_wrap button.ant-btn.ant-btn-default.ant-dropdown-trigger.btn_primary span {
        font-size: 15px;
    }
    .appointment-left ul{
        max-width: 100%;
    }
    .appointment-right {
        padding-left: 20px;
    }
  .about-cont p {
      font-size: 14px;
  }
  .prescription-cont {
      margin-bottom: 20px;
  }
  .about-img img {
      width: 100%;
  }
  .prescription-img img {
      max-width: 100%;
  }
}
@media (min-width:991.98px){
    aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
        display: none;
    }
} 
@media (max-width: 991.98px) {
    .about-title h2{
        font-size: 40px;
    }
    aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary button.ant-btn.ant-btn-link.sidebar-toggler {
        display: none;
    }
    aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary .menu_wrap, aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary .mobile_menu  {
        display: block !important ;
    }
    aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary .menu_wrap ul {
        display: block;
    }
    aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary .menu_wrap ul li {
        margin: 10px 0;
    }
    .menu_wrap button.ant-btn.ant-btn-default.ant-dropdown-trigger.btn_primary {
        margin:  0 !important;
        padding: 0   !important;
    }
    .container {
        max-width: 100% !important;
    }
    .serv-box {
      padding: 20px;
    }
    .prescription-img img {
        max-width: 100%;
    }
    .ant-layout-header .container {
        max-width: 100%;
    }
    .logo_wrap img {
        width: 100%;
    }
    .menu_wrap ul li {
        padding: 0 5px;
    }
    .menu_wrap ul li a {
        font-size: 12px;
    }
    .menu_wrap ul li .btn_primary {
        padding: 10px 15px !important;
        font-size: 14px !important;
    }
    .main-title h2 {
        font-size: 28px;
        margin-bottom: 5px;
    }
    .main-title p {
        font-size: 14px;
    }
    .prescription-cont ul li {
        font-size: 15px;
        padding-left: 15px;
        line-height: 1.4;
    }
    .number_box ul li h3 {
        font-size: 44px;
    }
    .number_box ul li span {
        font-size: 14px;
    }
    .number_box.contact_sec {
        padding: 25px 18px;
    }
    .number_box.contact_sec h3 {
        font-size: 28px;
        line-height: 1.4;
    }
    .f-contact ul li a {
        font-size: 13px;
    }
    .space-cls {
        margin: 45px 0 !important;
    }
    section.pharmacy-serv-sec.space-cls {
        padding: 45px 0;
        margin: 0 !important;
    }
    .serv-cont h4 {
        font-size: 18px;
    }
    .serv-cont p {
        font-size: 16px;
    }
    .serv-cont .ant-btn {
        font-size: 16px;
    }
    .about-independent-sec{
        padding: 45px 0;
    }
    section.contact-page.appointment-sec {
        margin: 0 !important
    }
}
@media (max-width: 767.98px) {
    .menu_wrap {
        display: none;
    }
    .about-img {
        margin-bottom: 15px;
    }
    .appointment-inner-list {
        flex-wrap: wrap;
    }
    .appointment-wrap {
        padding: 40px 0;
    }
    .appointment-left {
        margin-bottom: 20px;
    }
    .appointment-inner-list .appointment-left {
        margin-bottom: 0;
    }
    
    .appointment-right {
        padding: 0;
    }
    section.about-slider + section.about-sec.space-cls .row {
        flex-direction: column-reverse;
    }
    .footer-logo + .f-contact {
        max-width: 100%;
    }
    .f-widget-title {
        margin-top: 20px;
    }
    .working-hours p{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .f-contact ul li {
        margin-bottom: 5px;
    }
    .banner-tit-bg img {
        min-height: 290px;
        object-fit: cover;
    }
    .appointment-sec form.ant-form.ant-form-horizontal{
    max-width: 100%;
    }
    .number_box.contact_sec h3 {
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 15px;
    }
    .contact_inner {
        margin: 0 auto;
        display: table;
        text-align: center;
    }
    .number_box.contact_sec .ant-row.align-items-center {
        justify-content: center;
    }
    .help-wrap h5 {
        font-size: 18px;
        margin-bottom: 0;
    }
    
    .help-wrap p {
        font-size: 14px;
    }
    
    .help-wrap a {
        font-size: 15px;
    }
  .main-slider .owl-nav {
      padding: 0 10px;
  }

  .banner-inner-cont h1 {
      font-size: 40px;
  }

  .banner-img {
      padding-bottom: 75%;
      overflow: hidden;
      position: relative;
  }

  .banner-img img {
      height: 100%;
      position: absolute;
      object-fit: cover;
  }

  .banner-inner-cont {
      max-width: 100%;
      padding: 0 15px;
  }

  .main-title p {
      max-width: 100%;
  }
  .prescription-cont ul li {
    font-size: 14px;
  }
  .prescription-cont {
      margin-bottom: 20px;
  }
  .number_box ul li h3 {
      font-size: 28px;
  }

  .number_box ul li span {
      font-size: 14px;
  }
  .footer-link ul li {
        margin-bottom: 5px;
    }
  .prescription-cont {
      margin-bottom: 20px;
  }

  .about-img img {
      width: 100%;
  }
  .number_box ul li {
      padding: 10px 0;
      flex: 0 0 50%;
      max-width: 50%;
  }
  .number_box ul {
    flex-wrap: wrap;
  }
  .main-title h2 {
      font-size: 24px;
      line-height: 1.3;
  }
     .about-independent-sec .about-img {
        margin-bottom: 15px;
    }
    .owl-theme .owl-nav {
        width: 100%;
        padding: 0 5px;
        margin: 0;
      } 
      .owl-theme .owl-nav .owl-prev, .owl-theme .owl-nav .owl-next {
        margin: 0;
        height: 30px;
        width: 30px;
      }
      .number_box {
            padding: 15px 20px;
       }
        .appointment-cont {
            padding: 15px;
        }
        .btn_primary {
            font-size: 15px !important;
        }
        .prescription-img img {
            width: 100%;
        }
        .header-control {
            text-align: right;
        }
        .header-control .sidebar-toggler {
            padding: 0;
        }
        .appointment-inner-list .appointment-right {margin-top: 10px;}
}
@media (max-width:575.98px){
    
    .ant-modal.ModalCls.teamModal .ServiceDesc {
        flex-wrap:wrap;
    }
    
    .team-modal-img {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .ant-form-item.uploadWrap button.ant-btn img {
        max-width: 35px;
    }
    .ant-form-item.uploadWrap button.ant-btn span {
        font-size: 14px;
    }
    .appointment-left h5 {
        font-size: 16px;
    }
    .appointment-inner-list p {
        font-size: 14px;
    }
    .appointment-right span {
        font-size: 15px;
    }
    .banner-img {
        padding-bottom: 100%;
    }
    .number_box ul li span {
        font-size: 12px;
    }
    .banner-inner-cont p {
        font-size: 13px;
        max-width: 80%;
    }
    .about-title h2 {
        font-size: 30px;
    }
    .banner-inner-cont h1 {
        font-size: 30px;
    }
}
@media  (max-width: 480px) {

}
